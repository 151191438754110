import { notification } from 'antd';
import { DEFAULT_LIMIT_SIZE, NOTIFY_TYPE } from '../constants/enums';
import _ from 'lodash';
import { ANALYTICS, TYPE_MODULE_NAME } from '../constants/string';
import { UNIT } from '../constants/unit';

export const getCookieValue = (key: any) => {
  const allCookies = document.cookie;
  let storedValue = '';
  if (allCookies) {
    const cookieArray = allCookies.split(';');
    const filteredCookie = cookieArray.filter((element: any) => {
      const singleCookie = element.split('=');
      if (singleCookie[0].trim() === key) {
        storedValue = singleCookie[1];
      }
      return storedValue;
    });
  }
  return storedValue;
};

export const compareData = (obj1: any, obj2: any) => {
  if (obj1 === null && obj2 !== null) {
    return false;
  }
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;

  if (obj1Length === obj2Length) {
    return Object.keys(obj1).every((key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]);
  }
  return false;
};

export const calculatorDistance = (obj1: any, obj2: any) => {
  if (obj1 && obj2) {
    var R = 6371; // Radius of the earth in km
    let lat1 = (Math.PI / 180) * obj1?.lat;
    let lat2 = (Math.PI / 180) * obj2?.lat;
    let lon1 = (Math.PI / 180) * obj1?.lng;
    let lon2 = (Math.PI / 180) * obj2?.lng;

    let d = Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2));
    var dc = R * d; // Distance in km
    return dc;
  } else {
    return 0;
  }
};

export const checkInputFloat = (value: string) => {
  if (/^[+-]?\d+(\.\d+)?$/.test(value) || /^[+-]?\d+(\.)$/.test(value) || value == '') {
    return true;
  } else {
    return false;
  }
};

export const downloadCsvUtil = (contentData: any, name: string) => {
  contentData.forEach((e: any) => {
    for (let i = 0; i < e.length; i++) {
      if (typeof e[i] == 'string') {
        e[i] = e[i].replaceAll(',', ' ');
      }
    }
  });
  let csvContent = 'data:text/csv;charset=utf-8,' + contentData.map((e: any) => e.join(',')).join('\n');
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', name + '_' + new Date().toISOString().slice(0, 19) + '.csv');
  document.body.appendChild(link); // Required for FF

  link.click();
};

export const formatNumberSeparators = (data: any) => {
  if (data) {
    if (typeof data == 'number') {
      return parseFloat(data.toFixed(2)).toLocaleString();
    } else {
      return parseFloat(parseFloat(data).toFixed(2)).toLocaleString();
    }
  } else {
    return '0';
  }
};
export const formatNumberSeparatorsDefault = (data: any) => {
  if (data) {
    if (Number(data) < 1) {
      return data;
    }
    if (typeof data == 'number') {
      return parseFloat(data.toFixed(2)).toLocaleString();
    } else {
      return parseFloat(parseFloat(data).toFixed(2)).toLocaleString();
    }
  } else {
    return '0';
  }
};

export const formatNumberWithCommas = (x: any) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const handleShortenTheDigitAfterDecimalPlaces = (data: any) => {
  if (data === 0) {
    return data;
  }

  if (data) {
    const v = (typeof data === 'string' ? data : data.toString()).split('.');
    let f = v[1] || '';
    if (v.length === 1) {
      return data;
    } else if (v.length > 1) {
      return f.length < 3 ? `${v[0]}.${f.substr(0, 2)}` : `${v[0]}.${f.substr(0, 2)}` + '...';
    } else {
      return data;
    }
  } else {
    return '';
  }
};

/**
 * Open notification dialog
 * @param config
 * @param type
 */
export const OpenToast = (config: IToastConfig, type: string, className?: string) => {
  notification.config({
    top: 80,
    className,
  });

  switch (type) {
    case NOTIFY_TYPE.SUCCESS:
      notification.success(config);
      break;
    case NOTIFY_TYPE.ERROR:
      notification.error(config);
      break;
    case NOTIFY_TYPE.INFO:
      notification.info(config);
      break;
    case NOTIFY_TYPE.WARNING:
      notification.warning(config);
      break;
    default:
      notification.open(config);
      break;
  }
};

/**
 *Check file size upload limit size
 * @param files
 * @returns
 */
export const checkFileSizeLimit = (files: any): boolean => {
  let size = 0;
  if (Array.isArray(files)) {
    for (const file of files) {
      size += file.size / (1024 * 1024);
    }
  }
  if (files instanceof File) {
    size += files.size / (1024 * 1024);
  }
  if (size > DEFAULT_LIMIT_SIZE) {
    return true;
  }
  return false;
};

export const getFileExtFromFileName = (fileName: string): string => {
  let ext = fileName.split('.').pop() ?? '';
  return ext;
};

// Same debounce func from lodash
export const debounce = (func: any, delay: number) => {
  let timerId: any = '';

  return (...args: any[]) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

/**
 * Delete obj keys and reset keys
 * @param objWithKeysNumber:
 */
export const deleteAndResetObjectKeys = (object: any, key: number) => {
  delete object[key];
  while (++key in object) {
    object[key - 1] = object[key];
    delete object[key];
  }
};

/**
 * Round carbon emisssions
 * @param carbon // kg,
 * @return newCarbon && hover number // tons,kg
 */
export const roundCarbon = (carbon: number) => {
  if (carbon < 5) return { newCarbon: 0, hoverNum: { data: Number(carbon.toFixed(1)), showKg: true } };
  else if (5 <= carbon && carbon < 14.5) return { newCarbon: 0.01, hoverNum: { data: Number(carbon.toFixed(1)), showKg: true } };
  else if (14.5 <= carbon && carbon < 1000)
    return { newCarbon: Number((carbon / 1000).toFixed(1)), hoverNum: { data: Number(carbon.toFixed(1)), showKg: true } };
  else return { newCarbon: Number((carbon / 1000).toFixed(1)), hoverNum: { data: Number(carbon.toFixed(1)), showKg: true } };
};

/**
 * Create mock OT breakdown
 * @param year,
 * @return
 */
export const makeMockOtBdData = (year: number, arrLength = 3) => {
  const data = new Array(arrLength)
    .fill('')
    .map((_, i) => ({
      label: year - (i + 1),
      businessUnits: [],
    }))
    .sort((a, b) => a.label - b.label);
  return data;
};

export const getKeyByValue = (targetValue: any, obj: any) => {
  return _.findKey(obj, (value) => value === targetValue);
};

/**
 * Custom current Date
 * @param month,
 * @param year,
 * @return
 */
export const customCurrentDate = (month: number, year: number) => {
  let formattedDate = '';
  const now = new Date();
  // Get date, hour, minutes and seconds
  const day = now.getDate();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();
  const milliseconds = now.getMilliseconds();

  // In JavaScript, the number of months starts at 0 (0 for January, 1 for February, etc.).
  // Dayjs gives us the month starting at 0, therefore we have to add one.

  formattedDate = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(
    minutes,
  ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${milliseconds}Z`;

  return formattedDate;
};

export const formatNumber = (number: any) => {
  let parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const validateFormatNumber = (number: any) => {
  const parsedNumber = parseFloat(number);
  if (Number.isInteger(parsedNumber)) {
    return formatNumber(parsedNumber);
  } else {
    return formatNumber(parsedNumber.toFixed(2)).replace(/\.00$/, '');
  }
};

export const formatMillion = (number: any) => {
  if (number < 1e6) {
    return formatNumber(number);
  } else {
    return +(number / 1e6).toFixed(0) + ' M';
  }
};

export const renderClassNameSubChart = (nameModule: string) => {
  let className = '';
  switch (nameModule) {
    case TYPE_MODULE_NAME.SITE_USAGE:
      className = 'chart-detail-percent-site-usage';
      break;
    case TYPE_MODULE_NAME.MATERIALS:
      className = 'chart-detail-percent-materials';
      break;
    case TYPE_MODULE_NAME.TRAVELING:
      className = 'chart-detail-percent-traveling';
      break;
    case TYPE_MODULE_NAME.MACHINERY:
      className = 'chart-detail-percent-machinery';
      break;
    case TYPE_MODULE_NAME.SHIPPING:
      className = 'chart-detail-percent-shipping';
      break;
  }
  return className;
};
export const getCityAndCountryFilterResult = (listProject: any[]) => {
  const listCity = listProject.map((el: any) => {
    return {
      id: el.eco_city.id,
      name: el.eco_city.name,
      id_location: Number(el.eco_city.id_location),
      project_id: el.project_id,
      sub_project_id: el.id,
    };
  });
  const listCountry = listProject.map((el: any) => {
    return {
      id: el.eco_location.id,
      name: el.eco_location.name,
      project_id: el.project_id,
      sub_project_id: el.id,
    };
  });
  const objGr = groupCityAndCountryFilterResult(listCity, listCountry);
  return {
    grCity: objGr.grCity,
    grCountry: objGr.grCountry,
  };
};
export const groupCityAndCountryFilterResult = (listCity: any[], listCountry: any[]) => {
  let grCity: any = {};
  let grCountry: any = {};
  listCity.forEach((item: any) => {
    if (!grCity[item.id]) {
      grCity[item.id] = {
        id: item.id,
        name: item.name,
        id_location: Number(item.id_location),
        project_id: item.project_id,
        sub_project_id: item.sub_project_id,
      };
    }
  });
  listCountry.forEach((item: any) => {
    if (!grCountry[item.id]) {
      grCountry[item.id] = {
        id: item.id,
        name: item.name,
        project_id: item.project_id,
        sub_project_id: item.sub_project_id,
      };
    }
  });
  return {
    grCity: Object.values(grCity),
    grCountry: Object.values(grCountry),
  };
};
export const groupCityCountryBUnitBlineGPGroup = (list: any[]) => {
  let gr: any = {};
  list.forEach((item: any) => {
    if (!gr[item.id]) {
      gr[item.id] = {
        ...item,
      };
    }
  });
  return {
    gr: Object.values(gr),
  };
};
export const handleFilterAnalyticsOfListProject = (listProject: any[], valueSelect: any[], typeForm: string) => {
  const { BUSINESS_UNIT, GLOBAL_PRODUCT_GROUP, YEAR, BUSINESS_LINE, COUNTRY, PROJECT, CITY } = ANALYTICS.FILTER_BAR.LABELS;
  let projectFiltered: any[] = [];
  let businessUnitFiltered: any[] = [];
  let globalProductGroupFiltered: any[] = [];
  let businessLineFiltered: any[] = [];
  let yearFiltered: any[] = [];
  let cityFiltered: any[] = [];
  let countryFiltered: any[] = [];
  switch (typeForm) {
    case PROJECT:
      projectFiltered = listProject.filter((e: any) => valueSelect.includes(e.id_project));
      break;
    case BUSINESS_UNIT:
      projectFiltered = listProject.filter((e: any) => valueSelect.includes(e.eco_business_unit.id));
      break;
    case GLOBAL_PRODUCT_GROUP:
      projectFiltered = listProject.filter((e: any) => valueSelect.includes(e.eco_global_product_group.id));
      break;
    case BUSINESS_LINE:
      projectFiltered = listProject.filter((e: any) => valueSelect.includes(e.eco_business_line.id));
      break;
    case CITY:
      projectFiltered = listProject.filter((project: any) => {
        return project.eco_sub_projects.some((subProject: any) => valueSelect.includes(subProject.eco_city.id));
      });
      break;
    case COUNTRY:
      projectFiltered = listProject.filter((project: any) => {
        return project.eco_sub_projects.some((subProject: any) => valueSelect.includes(subProject.eco_location.id));
      });
      break;
    case YEAR:
      projectFiltered = listProject.filter((e: any) => valueSelect.includes(e.project_starting_year));
      break;
  }
  projectFiltered.forEach((project: any) => {
    businessUnitFiltered.push(project?.eco_business_unit);
    globalProductGroupFiltered.push(project?.eco_global_product_group);
    businessLineFiltered.push(project?.eco_business_line);
    yearFiltered.push(project?.project_starting_year);
    project.eco_sub_projects.forEach((sub: any) => {
      cityFiltered.push(sub.eco_city);
      countryFiltered.push(sub.eco_location);
    });
  });
  return {
    projectFiltered: projectFiltered,
    businessUnitFiltered: businessUnitFiltered,
    globalProductGroupFiltered: globalProductGroupFiltered,
    businessLineFiltered: businessLineFiltered,
    yearFiltered: yearFiltered,
    cityFiltered: cityFiltered,
    countryFiltered: countryFiltered,
  };
};
export const filterProjectsByCriteria = (
  projects: any,
  bUnitIds: any,
  bLineIds: any,
  gpGroupIds: any,
  countryIds: any,
  years: any,
  cityIds: any,
) => {
  return projects.filter((project: any) => {
    if (years && years.length > 0 && !years.includes(project.project_starting_year)) {
      return false;
    }
    if (bUnitIds && bUnitIds.length > 0 && !bUnitIds.includes(project.eco_business_unit.id)) {
      return false;
    }
    if (gpGroupIds && gpGroupIds.length > 0 && !gpGroupIds.includes(project.eco_global_product_group.id)) {
      return false;
    }
    if (bLineIds && bLineIds.length > 0 && !bLineIds.includes(project.eco_business_line.id)) {
      return false;
    }
    if (cityIds && cityIds.length > 0) {
      let found = project.eco_sub_projects.some((subProject: any) => {
        return cityIds.includes(subProject.eco_city && subProject.eco_city.id);
      });
      if (!found) {
        return false;
      }
    }
    if (countryIds && countryIds.length > 0) {
      let found = project.eco_sub_projects.some((subProject: any) => {
        return countryIds.includes(subProject.eco_location && subProject.eco_location.id);
      });
      if (!found) {
        return false;
      }
    }
    return true;
  });
};
export const handleFilterAnalyticsWhenSelect = (listProject: any) => {
  let businessUnitFiltered: any[] = [];
  let globalProductGroupFiltered: any[] = [];
  let businessLineFiltered: any[] = [];
  let yearFiltered: any[] = [];
  let cityFiltered: any[] = [];
  let countryFiltered: any[] = [];
  listProject.forEach((project: any) => {
    businessUnitFiltered.push(project?.eco_business_unit);
    globalProductGroupFiltered.push(project?.eco_global_product_group);
    businessLineFiltered.push(project?.eco_business_line);
    yearFiltered.push(project?.project_starting_year);
    project.eco_sub_projects.forEach((sub: any) => {
      cityFiltered.push(sub.eco_city);
      countryFiltered.push(sub.eco_location);
    });
  });
  return {
    businessUnitFiltered: businessUnitFiltered,
    globalProductGroupFiltered: globalProductGroupFiltered,
    businessLineFiltered: businessLineFiltered,
    yearFiltered: yearFiltered,
    cityFiltered: cityFiltered,
    countryFiltered: countryFiltered,
  };
};
export const checkUndefinedOrEmpty = (objectsValueForm: any) => {
  for (let key in objectsValueForm) {
    if (objectsValueForm.hasOwnProperty(key)) {
      if (!(objectsValueForm[key] === undefined || (Array.isArray(objectsValueForm[key]) && objectsValueForm[key].length === 0))) {
        return false;
      }
    }
  }
  return true;
};
export const filterSubProjectsFilter = (listSubProject: any, cityIds: any, countryIds: any) => {
  return listSubProject.filter((subProject: any) => {
    if (cityIds && cityIds.length > 0 && !cityIds.includes(subProject.eco_city.id)) {
      return false;
    }
    if (countryIds && countryIds.length > 0 && !countryIds.includes(subProject.eco_location.id)) {
      return false;
    }
    return true;
  });
};
export const isArrayEmptyOrUndefined = (arrSelectForm: any) => {
  if (arrSelectForm === undefined) {
    return true;
  }
  if (!Array.isArray(arrSelectForm)) {
    return false;
  }
  if (arrSelectForm.length === 0) {
    return true;
  }

  for (let element of arrSelectForm) {
    if (isArrayEmptyOrUndefined(element) === false) {
      return false;
    }
  }
  return true;
};
export const filterProjectsInCountryCity = (projects: any, countryIds: any, cityIds: any) => {
  return projects.filter((project: any) => {
    if (cityIds && cityIds.length > 0) {
      let found = project.eco_sub_projects.some((subProject: any) => {
        return cityIds.includes(subProject.eco_city && subProject.eco_city.id);
      });
      if (!found) {
        return false;
      }
    }
    if (countryIds && countryIds.length > 0) {
      let found = project.eco_sub_projects.some((subProject: any) => {
        return countryIds.includes(subProject.eco_location && subProject.eco_location.id);
      });
      if (!found) {
        return false;
      }
    }
    return true;
  });
};
export const compareArraysId = (arr1: any, arr2: any) => {
  const len1 = arr1.length;
  const len2 = arr2.length;
  const minLength = Math.min(len1, len2);
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return arr1[i];
    }
  }
  return len1 > len2 ? arr1[minLength] : arr2[minLength];
};
export const formatTooltipChartDoughnut = (context: any) => {
  let formatDataLabels: any = context;
  formatDataLabels = context?.replaceAll(',', ' ').replaceAll(' ', '');
  formatDataLabels = formatNumber(formatDataLabels ? Number(formatDataLabels).toFixed(1) : 0);
  formatDataLabels = formatDataLabels + ' ' + UNIT.UNIT_TCO + '₂' + UNIT.UNIT_EQ;
  return formatDataLabels;
};
export const formatDataLabelsPercent = (value: any, context: any) => {
  const totalCarbon = context?.dataset?.data?.reduce((a: any, b: any) => a + b, 0);
  let percent: any = 0;
  if (value !== undefined) {
    percent = ((value / totalCarbon) * 100).toFixed(0);
  }
  return percent >= 5 ? `${percent}%` : '';
};
